import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";

export default function useVoiceMails() {
  const t = inject("t");
  const store = useStore();
  const voicemail = ref({
    description: "",
    mail_to: "",
    mail_from: "",
    mail_cc: null,
    mail_bcc: null,
    mail_body: "",
    mail_subject: "",
    mail_signature: null,
    is_active: true,
  });
  const loading = ref(true);
  const voicemails = ref([]);
  const freeVoicemails = ref([]);
  const freeAgentVoicemails = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND VOICEMAILS
  const findVoicemails = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/voicemail");

      if (response?.data) voicemails.value = response.data;
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   FIND VOICEMAIL
  const findVoicemail = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/voicemail/${id}`);

      if (response?.data?.length) {
        voicemail.value = response.data[0];
      }
    } catch (error) {}
  };

  //   FIND FREE VOICEMAIL
  const findFreeVoicemail = async () => {
    try {
      const response = await api.get(`/admin/xpbx/free/settings/voicemail`);

      if (response?.data?.length) {
        freeVoicemails.value = response.data;
      }
    } catch (error) {}
  };

  //   FIND AGENT FREE VOICEMAIL
  const findAgentFreeVoicemails = async (id) => {
    try {
      const response = await api.get(
        `/admin/xpbx/free/settings/voicemail/${id}`
      );

      if (response?.data?.length) {
        freeAgentVoicemails.value = response.data;
      }
    } catch (error) {}
  };

  //   CREATE VOICEMAIL
  const createVoicemail = async (data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(`/admin/xpbx/settings/voicemail`, data);

      if (response?.data?.id) {
        voicemails.value = [response.data, ...voicemails.value];
        n("xpbx.settings.voicemails.notifications.create_voicemail", "success");
      } else {
        n("xpbx.settings.voicemails.notifications.error_create_voicemail");
      }
    } catch (error) {
      n("xpbx.settings.voicemails.notifications.error_create_voicemail");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE VOICEMAIL
  const updateVoicemail = async (data, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/voicemail/${id}`,
        data
      );

      if (response?.data?.id) {
        const index = voicemails.value.findIndex((i) => i.id === id);
        voicemails.value[index] = response.data;
        n("xpbx.settings.voicemails.notifications.edit_voicemail", "success");
      } else {
        n("xpbx.settings.voicemails.notifications.error_edit_voicemail");
      }
    } catch (error) {
      n("xpbx.settings.voicemails.notifications.error_edit_voicemail");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE VOICEMAIL
  const deleteVoicemail = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(`/admin/xpbx/settings/voicemail/${id}`);

      if (response.status === 200) {
        n("xpbx.settings.voicemails.notifications.delete_voicemail", "success");

        voicemails.value = voicemails.value.filter((i) => i.id !== id);
      } else {
        n("xpbx.settings.voicemails.notifications.error_delete_voicemail");
      }
      return response;
    } catch (error) {
      n("xpbx.settings.voicemails.notifications.error_delete_voicemail");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  return {
    loading,
    voicemail,
    voicemails,
    freeVoicemails,
    freeAgentVoicemails,
    findVoicemail,
    findVoicemails,
    updateVoicemail,
    createVoicemail,
    deleteVoicemail,
    findFreeVoicemail,
    findAgentFreeVoicemails,
  };
}
