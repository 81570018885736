<template>
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.settings.voicemail.heading')"
        :showButton="false"
      />
      <!-- Extensions table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="voicemails"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          tableStyle="min-width: 50rem"
          :paginator="true"
          v-if="!loading"
          :rows="10"
          :filters="filters"
          v-model:selection="selectedRecords"
          paginatorPosition="top"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} voicemails"
          selectionMode="single"
          dataKey="id"
          removableSort
          sortMode="multiple"
          v-model:filters="filters"
          :globalFilterFields="['description']"
        >
          <template #empty>
            {{ $t("xpbx.settings.fields.empty_table") }}</template
          >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
              </div>

              <div class="flex gap-2 items-center ml-auto">
                <InputText
                  class="search-input"
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
                <Export
                  :dt="dt"
                  :tableData="mappedDidNumbers"
                  :columns="voicemailsHeaders"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="description"
            style="width: 20%"
            :header="$t('xpbx.settings.voicemails.fields.description')"
          >
            <template #body="{ data }">
              {{ data?.description }}
              <TableActions
                :data="data"
                :id="data.id"
                @edit="editHandle"
                :showIsActive="true"
                @setActive="setActive"
                :isActive="data.is_active"
                @delete="deleteSelectedRecord"
              />
            </template>
          </Column>
          <Column
            sortable
            style="width: 15%"
            field="mail_subject"
            :header="$t('xpbx.settings.voicemails.fields.mail_subject')"
          />
          <Column
            sortable
            style="width: 10%"
            field="mail_from"
            :header="$t('xpbx.settings.voicemails.fields.mail_from')"
          />
          <Column
            sortable
            style="width: 10%"
            field="mail_to"
            :header="$t('xpbx.settings.voicemails.fields.mail_to')"
          />

          <Column
            sortable
            field="created_at"
            style="width: 15%"
            :header="$t('xpbx.settings.voicemails.fields.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.created_at" />
            </template>
          </Column>
          <Column
            sortable
            field="updated_at"
            style="width: 15%"
            :header="$t('xpbx.settings.voicemails.fields.updated_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.updated_at" /> </template
          ></Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- Dialogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span class="text-center">{{
            $t("xpbx.settings.voicemails.notifications.confirm_delete", {
              delete: `voicemail ${
                selectedRecord?.description ? selectedRecord.description : ""
              }`,
            })
          }}</span>
        </div>
        <template #footer>
          <Button
            text
            :label="$t('xpbx.button.no')"
            @click="deleteRecordsDialog = false"
          />
          <Button
            text
            :label="$t('xpbx.button.yes')"
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create/Edit Voicemail -->
      <Dialog
        v-model:visible="voicemailDialog"
        :style="{ width: '800px' }"
        :header="
          isEdit
            ? $t('xpbx.settings.voicemails.dialogs.edit_voicemail')
            : $t('xpbx.settings.voicemails.dialogs.create_voicemail')
        "
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!-- Description -->
        <FormInput
          id="description"
          :error="errors?.description?.[0]"
          v-model:modelValue="voicemail.description"
          :label="$t('xpbx.settings.voicemails.labels.description')"
          :hint="$t('xpbx.settings.voicemails.hints.description')"
        />

        <!-- Email body -->
        <FormTextArea
          id="mail_body"
          :error="errors?.mail_body?.[0]"
          v-model:modelValue="voicemail.mail_body"
          :label="$t('xpbx.settings.voicemails.labels.mail_body')"
          :hint="$t('xpbx.settings.voicemails.hints.mail_body')"
        />

        <!-- Email subject -->
        <FormInput
          id="mail_subject"
          :error="errors?.mail_subject?.[0]"
          v-model:modelValue="voicemail.mail_subject"
          :label="$t('xpbx.settings.voicemails.labels.mail_subject')"
          :hint="$t('xpbx.settings.voicemails.hints.mail_subject')"
        />

        <!-- Email from -->
        <FormInput
          id="mail_from"
          :error="errors?.mail_from?.[0]"
          v-model:modelValue="voicemail.mail_from"
          :label="$t('xpbx.settings.voicemails.labels.mail_from')"
          :hint="$t('xpbx.settings.voicemails.hints.mail_from')"
        />

        <!-- Email to -->
        <FormInput
          id="mailmail_to_from"
          :error="errors?.mail_to?.[0]"
          v-model:modelValue="voicemail.mail_to"
          :label="$t('xpbx.settings.voicemails.labels.mail_to')"
          :hint="$t('xpbx.settings.voicemails.hints.mail_to')"
        />

        <!-- Email cc -->
        <FormInput
          id="mail_cc"
          v-model:modelValue="voicemail.mail_cc"
          :label="$t('xpbx.settings.voicemails.labels.mail_cc')"
          :hint="$t('xpbx.settings.voicemails.hints.mail_cc')"
        />

        <!-- Email bcc -->
        <FormInput
          id="mail_bcc"
          v-model:modelValue="voicemail.mail_bcc"
          :label="$t('xpbx.settings.voicemails.labels.mail_bcc')"
          :hint="$t('xpbx.settings.voicemails.hints.mail_bcc')"
        />

        <!-- Email signature -->
        <FormTextArea
          id="mail_signature"
          v-model:modelValue="voicemail.mail_signature"
          :label="$t('xpbx.settings.voicemails.labels.mail_signature')"
          :hint="$t('xpbx.settings.voicemails.hints.mail_signature')"
        />

        <InputSwitch
          v-model:modelValue="voicemail.is_active"
          :label="$t('xpbx.settings.voicemails.labels.is_active')"
        />

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="create"
            @cancel="voicemailDialog = false"
          />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useVoiceMails from "@/modules/xpbx/composables/useVoiceMails";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Tooltip from "primevue/tooltip";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormTextArea from "@/modules/xpbx/components/forms/FormTextArea.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import { validateCreateVoicemail } from "@/composables/auth/createNumberValidations";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import { voicemailsHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "VoiceMail",

  components: {
    PageHeader,
    DateItem,
    DataTable,
    Column,
    Loader,
    Button,
    InputText,
    Dialog,
    Export,
    FormInput,
    TableActions,
    FormTextArea,
    InputSwitch,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const {
      loading,
      findVoicemails,
      voicemails,
      voicemail,
      createVoicemail,
      updateVoicemail,
      deleteVoicemail,
    } = useVoiceMails();
    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const voicemailDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const resetVoicemail = () => {
      voicemail.value = {
        description: "",
        mail_to: "",
        mail_from: "",
        mail_cc: null,
        mail_bcc: null,
        mail_body: "",
        mail_subject: "",
        mail_signature: null,
        is_active: true,
      };
      submitted.value = false;
      clearErrors();
    };

    const mappedDidNumbers = computed(() => {
      return voicemails.value.map((item) => {
        return {
          ...item,
          is_active: item.is_active == 1 ? "Is active" : "Inactive",
        };
      });
    });

    const openNew = () => {
      isEdit.value = false;
      voicemailDialog.value = true;
    };

    const closeDialog = (value) => {
      voicemailDialog.value = value;
    };

    const update = async () => {
      try {
        const form = {
          ...voicemail.value,
          is_active: voicemail.value.is_active === true ? 1 : 0,
        };

        await updateVoicemail(form, voicemail.value.id);
        createCallback();
      } catch (error) {
        createCallback();
      }
    };

    const createCallback = () => {
      isEdit.value = false;
      submitted.value = false;
      voicemailDialog.value = false;
    };

    const createRecord = async () => {
      submitted.value = true;
      const isValid = validateCreateVoicemail(voicemail.value);

      if (isValid) {
        const formData = {
          ...voicemail.value,
          is_active: voicemail.value.is_active === true ? 1 : 0,
        };

        try {
          await createVoicemail(formData);
          createCallback();
        } catch (error) {
          createCallback();
        }
      }
    };

    const create = async () => {
      if (isEdit.value) {
        await update();
      } else {
        await createRecord();
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteVoicemail(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const editHandle = (data) => {
      const form = {
        ...data,
        mail_cc: data.mail_cc ? data.mail_cc : "",
        mail_bcc: data.mail_bcc ? data.mail_bcc : "",
        mail_signature: data.mail_signature ? data.mail_signature : "",
        is_active: data.is_active === 1 ? true : false,
      };

      voicemail.value = form;
      isEdit.value = true;
      selectedRecord.value = data;
      voicemailDialog.value = true;
    };

    const setActive = async (data) => {
      const form = {
        ...data,
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await updateVoicemail(form, data.id);
    };

    watch(voicemailDialog, (value) => {
      if (!value) resetVoicemail();
    });

    onMounted(async () => {
      // checkTableClick();
      await findVoicemails();
    });

    const types = [
      { name: "active", value: 1 },
      { name: "inactive", value: 0 },
    ];

    return {
      dt,
      types,
      voicemailsHeaders,
      isEdit,
      loading,
      errors,
      filters,
      submitted,
      voicemail,
      voicemails,
      voicemailDialog,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      scrollHeight,
      // Methods
      create,
      openNew,
      editHandle,
      setActive,
      findVoicemails,
      closeDialog,
      mappedDidNumbers,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
      resetVoicemail,
    };
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.select-autocomplete .form-control {
  border: 1px solid #94a3b8;
}
</style>